* {
  box-sizing: border-box;
}

._navbarlinks {
  display: flex;
  justify-content: space-around;
}
._navbarlinks a {
  color: rgb(44, 43, 43);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._navbarlinks a:hover {
  color: rgb(156, 154, 154) !important;
  text-decoration: none;
}
._navbarlinksadmin {
}
._navbarlinksadmin a {
  color: rgb(44, 43, 43);
}

._navbarlinksadmin a:hover {
  color: rgb(156, 154, 154) !important;
  text-decoration: none;
}
.gallery_container {
  width: 90%;
  margin: auto;
}
/* .image-gallery {
}
.image-gallery-slide img {
}
.image-gallery-slides {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
} */

.image-gallery {
  max-width: 800px;
  margin: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

._houseDescription {
  width: 65vw;
}

._hero_filter {
  min-width: 200px;
}

._select_options {
  height: 80px;
}

._select_options_filter {
  height: 80px;
}

._img_hover {
  transform: scale(1, 1);
  transition: transform 0.8s;
}

._img_hover:hover {
  transform: scale(1.08, 1.08);
}

._img_hover:hover ._item_hover {
  color: black;
  background-color: rgba(255, 255, 255, 0.603);
}

._img_hover:hover ._subtitle {
  color: white;
}

._admin_houses {
  width: 100%;
  cursor: pointer;
  border: none;
  background: white;
}

._admin_houses:focus {
  background: lightgrey;
}

._advanceLink {
  color: rgb(65, 62, 62);
}

._advanceLink:hover {
  color: white;
}

._collapseAdvanceFilter {
  width: 60vw;
}

._item_hover {
  width: "25%";
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

._subtitle {
  font-size: 14px;
  color: #bdc2bf;
}

._badgeHighlight::after {
  position: absolute;
  content: attr(data-label);
  top: 15px;
  right: -70px;
  padding: 20px;
  width: 300px;
  background: #d83209;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transform: rotate(35deg);
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.664);
}

._badgeReserved::after {
  position: absolute;
  content: attr(data-label);
  top: 15px;
  right: -70px;
  padding: 20px;
  width: 300px;
  background: #0c0fe7;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transform: rotate(35deg);
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.664);
}

._badgeSold::after {
  position: absolute;
  content: attr(data-label);
  top: 15px;
  right: -70px;
  padding: 20px;
  width: 300px;
  background: #10d41a;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transform: rotate(35deg);
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.664);
}

._contactContainer {
  width: 70vw;
}

._contactsFoto {
  height: 400px;
}

._homeHeroText {
  margin-top: 150px;
}
@media only screen and (max-width: 1220px) {
  ._houseDescription {
    width: 100vw;
  }
  ._houseEmailAndCharactContainer {
    flex-direction: column-reverse;
  }
  ._collapseAdvanceFilter {
    width: 80vw;
  }
  ._contactContainer {
    width: 85vw;
  }
  ._homeHeroText {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 1050px) {
  ._hero_filter {
    min-width: 60vw;
  }
  ._select_options {
    height: 60px;
  }
  ._select_options_filter {
    height: 50px;
  }
  ._contactContainer {
    width: 90vw;
  }
  ._contactTextTitle {
    font-size: 28px;
    font-weight: bold;
  }
  ._homeHeroText {
    margin-top: 75px;
  }
}
@media only screen and (max-width: 700px) {
  ._collapseAdvanceFilter {
    width: 95vw;
  }
  ._contactTextTitle {
    font-size: 22px;
    font-weight: bold;
  }
  ._contactsFoto {
    width: 200px;
    height: 300px;
    margin: auto;
  }
  ._contactsFoto p {
    text-align: center;
  }
  ._homeHeroText {
    margin-top: 50px;
    font-size: 40px;
  }
}
